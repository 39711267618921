.main-container{
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: 
    linear-gradient(180deg, var(--dark-primary-color) 5%, var(--primary-color) 60%);

}

.main-inner-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.main-background-img{
    background:  url('../../assets/woman2.jpeg');
    mix-blend-mode: screen;
    background-repeat: no-repeat;
    position: absolute;
    top: -10px;
    width: 100%;
    height: 100%;
    filter: grayscale(100%) ;
    opacity: 40%;
}