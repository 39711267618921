.calendar{
    background-color:  rgba(256, 256, 256, 85%)!important;
    margin-bottom: 1rem!important;
    width: 75%!important;
}


.Mui-selected{
    background-color: VAR(--primary-color)!important;
}
