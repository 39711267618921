@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #a000ff;
    color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 65px;
    animation: fadeIn 1.5s ease-in-out;
    border-top: 1px solid #ccc;
    z-index: 1000;
  }
  
  .footer button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 0;
    margin: 0;
    outline: none; /* Elimina el borde de enfoque */
    width: 100%;
    height: 100%;
  }
  
  .footer button.active {
    background-color: rgba(0, 0, 0, 0.3); /* Fondo más oscuro o transparente */
  }
  