@import '../../variables/variables.css';

.form-container{
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
margin: 1rem 0;
}

.inputs{
    font-family: "Inter", sans-serif!important;
    font-weight: 900!important;
    margin-bottom: 1rem!important;
border-bottom:2px solid white!important;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
    color: white!important;
}
.inputs::placeholder {
    color: white!important;
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input{
    color: white !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input{
    color: white !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root{
    color: white !important;
}

.css-cv41kj-MuiInputBase-root-MuiInput-root::before{
    border-bottom: 1px solid white;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
    color: white !important;
}

.primary-button{
    font-family: "Inter", sans-serif!important;
    font-weight: 600!important;
    background-color: var(--tertiary-color)!important;
    margin-bottom: 0.5rem!important;
    min-width: 219px!important;
    margin-top: 0.5rem!important;
}

.span-line{
    color: var(--white);
    margin-bottom: 0.25rem!important;
}

.secondary-button{
    font-family: "Inter", sans-serif!important;
    font-weight: 600!important;
    margin-bottom: 1rem!important;
    color: var(--white)!important;
    border-color: var(--white)!important;
    border-width: 1.5px!important;
    min-width: 219px!important;
}

.auth-errors{
    color: var(--white)!important;
    font-weight: 600!important;
    text-shadow: 1px 1px var(--tertiary-color);
}