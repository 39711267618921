@import '../../variables/variables.css';

.background-img{
    background:  url('../../assets/woman2.jpeg');
    mix-blend-mode: screen;
    background-repeat: no-repeat;
    position: absolute;
    top: -40px;
    width: 100%;
    height: 100%;
    filter: grayscale(100%) ;
    opacity: 40%;
    
}

.login-container{
     height: 100vh;
    display: flex;
    flex-direction: column;
    background: 
    linear-gradient(180deg, var(--dark-primary-color) 5%, var(--primary-color) 60%);
}

.login-inner-container{
   margin-top: 60%;
}

.login-title{
    font-family: "Inter", sans-serif!important;
    font-weight: 900!important;
    color: var(--white);
    text-transform: uppercase;
    text-shadow: -7px -5px var(--tertiary-color);
    text-align: center;
}

