.lesson-component {
  background-color: #f8f9fa;
  border: 1px solid #a000ff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 2px 2px  #a000ff;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  width: 320px;

  /* Estilo para cuando se pasa el mouse sobre el componente */
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
}

h3{
  color: #a000ff;
}

.lesson_info{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.lesson_title{
  align-items: center;
}

.right{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.right button{
  width: 100%;
  height: 50%;
  justify-content: center;
  display: flex;
  
  align-items: center;
}
.left{
  width: 70%;
  align-items: center;
  justify-content: space-around;
}

.lesson-component  button {
  background-color: white; /* Color de fondo verde */
  color: #a000ff;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s; /* Transición suave */
  border: 2px solid #a000ff;
}

.lesson-component button:hover {
  background-color: #a000ff; /* Cambio de color al pasar el mouse */
  color: white;
}


form button{
  display: flex;
  align-items: center;
  justify-content: center;
}