/* Estilos para el formulario de autenticación */
.auth_form {
    width: 300px;
    margin: auto;
    padding: 20px;
    border-radius: 8px;
    background-color: #ffffff; /* Fondo blanco */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
    opacity: 0;
    animation: fadeIn 1s ease forwards; /* Animación de aparición */
    animation-delay: 2.5s;
    border: solid #a000ff 2px;
    box-shadow: 2px 2px black;
}

@keyframes fadeIn {
  from {
    opacity: 0; /* El formulario comienza completamente transparente */
    transform: translateY(-20px); /* También puedes agregar una animación de desplazamiento */
  }
  to {
    opacity: 1; /* El formulario se vuelve completamente visible */
    transform: translateY(0); /* El formulario vuelve a su posición original */
  }
}
  
  .auth_form input[type="text"],
  .auth_form input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .auth_form button {
    width: 100%;
    background-color: white; /* Color de fondo verde */
    color: #a000ff;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s; /* Transición suave */
    border: 2px solid #a000ff;
    display: flex;
    justify-content: center;
  }
  
  .auth_form button:hover {
    background-color: #a000ff; /* Cambio de color al pasar el mouse */
    color: white;
  }
  

  form button{
    display: flex;
    align-items: center;
    justify-content: center;
}