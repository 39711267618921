.class-purchase {
    margin-top:60px ;
    max-width: 600px;
    margin-bottom: 120px;
    border-radius: 15px;
  }
  
  .class-purchase h1 {
    text-align: center;
    color: #a000ff;
    margin-bottom: 20px;
    font-size: 24px;    
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  }
  
  .payment-option {
    padding: 20px;
    border-radius: 10px;
    background-color: #F3F3F3;
    text-align: center;
    color: #a000ff;
    margin-bottom: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .payment-option:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }
  
  .payment-option h2 {
    margin: 0;
    font-size: 20px;
  }
  
  .payment-option p {
    margin: 10px 0;
    font-size: 16px;
  }
  
  .payment-option strong {
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
  }
  
  .payment-option button {
    background-color: #a000ff;
    color: #FFFFFF;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .payment-option button:hover {
    background-color: #7A1ED2;
  }
  
  @media (max-width: 600px) {
    .class-purchase {
      padding: 10px;
    }
  
    .payment-option {
      padding: 15px;
    }
  
    .payment-option h2 {
      font-size: 18px;
    }
  
    .payment-option p {
      font-size: 14px;
    }
  
    .payment-option strong {
      font-size: 16px;
    }
  
    .payment-option button {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
  