.css-hip9hq-MuiPaper-root-MuiAppBar-root {
    background-color: rgba(256, 256, 256, 85%)!important;
}

.css-5iifl-MuiPaper-root-MuiAppBar-root{
    background-color: rgba(256, 256, 256, 85%)!important;
}

.nav{
    margin-bottom: 2rem;
    position: fixed;
    top: 0;
    
}

.nav-container{
    justify-content: end;
}