/* index.css */

/* Contenedor del selector de fecha */
.calendar-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #a000ff;
  margin-bottom: 30px;
}


.DatePicker_container__EKHEI {
  display: flex;
  width: fit-content;
  max-width: 380px;
  background: inherit;
}

/* Ocultar el botón del selector de fecha
.DatePicker_button__iBgLD {
  display: none;
} */

/* Etiqueta del día del calendario */
.DatePicker_dayLabel__o2Brk {
  font-size: 12px;
  margin: 4px 0 0 0;
}

/* Estilo para cada día del calendario */
.DatePicker_dateDayItem__XwRQy {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0 5px;
  width: 40px;
  height: 49px;
  flex-shrink: 0;
  font-size: 30px;

  justify-content: center;
}

/* Estilo para el día seleccionado */
.DatePicker_dateDayItem__XwRQy.selected {
  background-color: red; /* Fondo cuando está seleccionado */
  color: #ffffff; /* Texto en color blanco cuando está seleccionado */
  border-color: #a000ff; /* Borde cuando está seleccionado */
}


.DatePicker_monthYearLabel__T6Ong {
  display: none;
}