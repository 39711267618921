.general {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    transition: box-shadow 0.3s ease;
    margin-top: 140px;
    display: flex;
    flex-direction: column;
    width: 300px;
    color: #a000ff;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    margin-bottom: 100px;
  }
  
 
  
  .info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border: #a000ff 1px solid;
    border-radius: 8px;
    padding: 20px;
  }
  
  .info-section p {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    display: flex;
    align-items: flex-start;
  }
  
  .info-section svg {
    font-size: 80px; /* Puedes ajustar este valor según el tamaño deseado */
  }

  .change-email {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .change-email form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .change-email input {
    padding: 10px;
    border: 1px solid #a000ff;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .change-email button {
    /* padding: 10px 20px; */
    background-color: #a000ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .change-email button:hover {
    background-color: #8700cc;
  }
  
  