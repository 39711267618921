button {
    background-color: white; /* Color de fondo verde */
    color: #a000ff;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s; /* Transición suave */
    border: 2px solid #a000ff;
  }

.lesson_container{
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-left: 18px;
}

  p,h1{

    color: #a000ff;
  }

  
  .active-button {
    background-color: #a000ff;
    color: white;
  }
  

  .filters{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  @keyframes fadeIn {
    from {
      opacity: 0; /* El formulario comienza completamente transparente */
      transform: translateY(-20px); /* También puedes agregar una animación de desplazamiento */
    }
    to {
      opacity: 1; /* El formulario se vuelve completamente visible */
      transform: translateY(0); /* El formulario vuelve a su posición original */
    }
  }
  .no_lesson{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 100%;
    height: 100%;
    align-items: center;
    animation: fadeIn 1s ease forwards; /* Animación de aparición */
  }
  