.card-lesson-container{
    width:100%!important;
    background-color:  rgba(256, 256, 256, 85%)!important;
    margin-bottom: 0.25rem!important;
}

.lessons-button{
    font-family: "Inter", sans-serif!important;
    font-weight: 600!important;
    color: var(--primary-color)!important;
    border-color: var(--primary-color)!important;
    border-width: 1.5px!important;
}