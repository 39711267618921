.me {
  background-color: #f8f9fa;
  border: 1px solid #a000ff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 2px 2px #a000ff;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  width: 300px;
  color: #a000ff;
  align-items: center;
  justify-content: center;
}

.me:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

h1 {
  color: #a000ff;
}

h3 {
  color: #a000ff;
}

.container {
  margin-top: 100px;
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no_booked {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  animation: fadeIn 1s ease forwards;
}

.change-username {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.change-username form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.change-username input {
  padding: 10px;
  border: 1px solid #a000ff;
  border-radius: 4px;
  margin-bottom: 10px;
}

.change-username button {
  /* padding: 10px 20px; */
  background-color: #a000ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.change-username button:hover {
  background-color: #8700cc;
}

