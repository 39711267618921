.reset_password_screen {
    width: 320px;
    margin: auto;
    padding: 20px;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border: 2px solid #a000ff;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 100px;
}

/* Título del formulario */
.reset_password_screen h2 {
    font-size: 1.5em;
    color: #a000ff;
    margin-bottom: 20px;
    font-weight: bold;
}

/* Estilos para los inputs */
.reset_password_screen input[type="text"] {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 2px solid #a000ff;
    border-radius: 6px;
    box-sizing: border-box;
    font-size: 1em;
}

/* Estilos para los botones */
.reset_password_screen button {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    background-color: #ffffff;
    color: #a000ff;
    font-size: 1em;
    font-weight: bold;
    border: 2px solid #a000ff;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    justify-content: center;
}

/* Hover en los botones */
.reset_password_screen button:hover {
    background-color: #a000ff;
    color: #ffffff;
}

/* Mensaje de feedback */
.reset_password_screen p {
    margin-top: 15px;
    color: #a000ff;
    font-size: 0.9em;
}
