input {
    box-sizing: border-box;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
  }
  
  form > div {
    margin-bottom: 16px;
  }
  